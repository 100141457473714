/** @jsx jsx */
import { Box, jsx, useThemeUI } from 'theme-ui'
import Layout from './layout'
import Halo from './halo'
import Intro from './intro'
import Navigation from './navig'

interface PageFrameProps {
  title?: string
  desc?: string
  variant?: string
  children: any
  invert?: boolean
  alt?: boolean
  adjustHeight?: string
}

const PageFrame = ({
  title,
  desc,
  children,
  variant = 'box.plateBig',
  invert = false,
  alt = true,
  adjustHeight = '8rem',
}: PageFrameProps) => {
  const { theme } = useThemeUI()
  const removehash = (h: string) => {
    return h.replace('#', '')
  }
  return (
    <Layout>
      <Navigation alt={alt} invert={invert} />
      <Box variant={variant} sx={{ p: 0 }}>
        <Halo title={`${title} | Indelible Ink`} url="https://indelibleink.io/about" />
        <Box variant="box.plateMedium0" sx={{ color: 'text', m: 0, pl: 0, ml: 0, pt: adjustHeight }}>
          {title && <Intro title={title} description={desc} />}
          {children}
        </Box>
      </Box>
    </Layout>
  )
}

export default PageFrame
