/** @jsx jsx */
import { FC, useContext, lazy, Suspense, useState } from 'react'
import { Flex, Box, jsx, Text, Container, Divider, Label, Input, Button } from 'theme-ui'

import Halo from '../components/halo'
import PageFrame from '../components/page'
import { WalletContext } from '../contexts/dapp'

import { useDialogState, Dialog, DialogBackdrop, DialogDisclosure } from 'reakit/Dialog'

import Loadable from "@loadable/component"
const LazyConnect = Loadable(() => import("../components/connect"))

export interface RageStakePageProps {
  data: any
}

const RageStakePage: FC<RageStakePageProps> = ({ data }) => {
  const isSSR = typeof window === 'undefined'
  const context = useContext(WalletContext)
  // const [stake, setStake] = useState<boolean>(false)
  const dialog = useDialogState({ animated: true })

  const [isVisible, setIsVisible] = useState<boolean>(false)

  const onStake = () => {
    // setStake(1)
    setIsVisible(true)
  }

  const onUnStake = () => {
    // setStake(3)
    setIsVisible(true)
  }

  const onCloseStake = () => {
    // setStake(0)
    setIsVisible(false)
  }

  const closeMe = () => {
    dialog.hide
  }

  return (
    <PageFrame variant="box.plateTint3" invert={false} alt={false} adjustHeight={'3rem'}>
      <Halo title="FAQ" url="https://nft.rage.fan/faq" />
      <Box sx={{ bg: 'base', mt: 8 }}>
        <Box sx={{ py: 2, maxWidth: '60ch', mx: 'auto', pt: 4, pb: 3 }}>
          <Container mb={5} sx={{ px: [5, 0, 0] }}>
            {!isSSR && (
              <Suspense fallback={<div />}>
                <LazyConnect chainId={1} address="10,000" onStake={onStake} />
              </Suspense>
            )}
          </Container>
          <Text bg="red" color="white">{`Modal ${isVisible}`}</Text>
          {/* {stake > 0 && ( */}
          {/* {isVisible && ( */}
          <Box>
            <DialogBackdrop
              {...dialog}
              visible={isVisible}
              sx={{
                display: isVisible ? 'flex' : 'none',
                position: 'fixed',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: 'rgba(0,0,0,0.5)',
                zIndex: 90000,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Dialog
                {...dialog}
                aria-label="Stake Form"
                sx={{
                  bg: 'blue.4',
                  px: 5,
                  py: 4,
                  m: 2,
                  minWidth: '40ch',
                  minHeight: '30ch',
                }}
              >
                <Box bg="blue">
                  <Box sx={{ pb: 2 }}>
                    <Text variant="blockTitle">Stake</Text>
                  </Box>
                  <Label sx={{ color: 'grays.4', fontSize: 1, fontWeight: 'bold', mb: 2 }}>Stake</Label>
                  <Box sx={{ position: 'relative', mb: 3 }}>
                    <Input
                      // type="number"
                      value="01.023121321"
                      sx={{ border: 'solid 1px', borderColor: 'grays.0', bg: 'grays.0', pl: 3, fontSize: 3 }}
                    />
                    <Text sx={{ fontSize: 0, pt: 2, opacity: 0.65, position: 'absolute', right: 4, top: 1 }}>RAGE</Text>
                  </Box>
                  <Flex>
                    <Button>Stake</Button>
                    <Button onClick={() => setIsVisible(false)} sx={{ ml: 2, bg: 'grays.0', color: 'grays.3' }}>
                      Close
                    </Button>
                  </Flex>
                </Box>
              </Dialog>
            </DialogBackdrop>
          </Box>
          {/* )} */}
        </Box>
      </Box>
    </PageFrame>
  )
}

export default RageStakePage
