/** @jsx jsx */

import { FC } from 'react'
import { Box, jsx, Text } from 'theme-ui'

export interface IntroProps {
  date?: string
  title: string
  description: string
}

const Intro: FC<IntroProps> = ({ date, title, description }) => {
  return (
    <Box pb={2}>
      <Text as="h1" sx={{ fontSize: 5, fontWeight: 'bold', color: 'grays.8'}}>{title}</Text>
      <Text as="p" sx={{ my: 3, fontSize: 3, color: 'primarytext' }}>
        {description}
      </Text>
    </Box>
  )
}

export default Intro
